import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { selectIsAuthenticated } from 'store/reducers/authSlice';
import path from '../../path';

// @ts-ignore
const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={`${path.LOGIN}?next=${props.location.pathname}`} />
                )
            }
        />
    );
};

export default PrivateRoute;
