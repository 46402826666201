import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

const Copyright = () => (
    <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.lawmedicine.com.py/">
            LAW & Medicine
        </Link>{' '}
        {new Date().getFullYear()}.
    </Typography>
);

const Footer = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Copyright />
        </div>
    );
};

export default Footer;
