import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouteLink, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { actions, selectInvoice, selectLoading, selectError } from 'store/reducers/invoicesSlice';
import Loading from 'components/Loading';
import Error from 'components/Error';
import { currencyFormatter, dateFormatter } from 'helpers';
import { downloadInvoice } from 'api';
import path from '../../path';
import useStyles from './styles';

const Invoice = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const invoice = useSelector(selectInvoice);
    const loading = useSelector(selectLoading);
    const error = useSelector(selectError);

    const { invoiceId } = useParams<{ invoiceId: string }>();

    useEffect(() => {
        if (invoiceId) {
            dispatch(actions.fetchInvoice(invoiceId));
        }
        return () => {
            dispatch(actions.clean());
        };
    }, []);

    const BackButton = () => (
        <Button component={RouteLink} to={path.HOME} color="primary">
            <KeyboardBackspaceIcon className={classes.icon} /> Todas las facturas
        </Button>
    );

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return (
            <div>
                <BackButton />
                <Error />
            </div>
        );
    }
    if (invoice === null) {
        return <div />;
    }

    const onDownloadClick = async () => {
        if (invoice.url) {
            window.open(invoice.url, '_blank');
            return;
        }
        const invoiceData = await downloadInvoice(invoiceId);
        const url = window.URL.createObjectURL(new Blob([invoiceData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoice.number}.pdf`);
        link.click();
    };

    return (
        <div>
            <BackButton />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4">Datos de factura</Typography>
                        <Button
                            aria-label="download-invoice"
                            color="primary"
                            variant="contained"
                            onClick={onDownloadClick}
                        >
                            <DownloadIcon className={classes.icon} /> Descargar
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h6">Número</Typography>
                            <Typography>{invoice.number}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">Documento</Typography>
                            <Typography>{invoice.document}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">Fecha</Typography>
                            <Typography>{dateFormatter(invoice.date)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Detalles de factura</Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="invoice items">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Descripción</TableCell>
                                    <TableCell align="right">Monto</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoice.lines.map((line) => (
                                    <TableRow key={line.id}>
                                        <TableCell>{line.description}</TableCell>
                                        <TableCell align="right">{currencyFormatter.format(line.amount)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell align="right">{currencyFormatter.format(invoice.amount)}</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default Invoice;
