import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';

import { Invoice } from 'models/invoice';
import { currencyFormatter, dateFormatter, reverse } from 'helpers';
import path from '../../path';
import useStyles from './styles';

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }),
)(TableRow);

interface InvoicesTableProps {
    invoices: Invoice[];
}

const InvoicesTable = (props: InvoicesTableProps) => {
    const { invoices } = props;
    const classes = useStyles();
    const history = useHistory();
    const onRowClick = (invoiceId: string) => () => history.push(reverse(path.INVOICE, { invoiceId }));

    return (
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Número</TableCell>
                    <TableCell align="center">Fecha</TableCell>
                    <TableCell align="right">Monto</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {invoices.map((invoice) => (
                    <StyledTableRow key={invoice.id} hover onClick={onRowClick(invoice.id)}>
                        <TableCell component="th" scope="row">
                            {invoice.number}
                        </TableCell>
                        <TableCell align="center">{dateFormatter(invoice.date)}</TableCell>
                        <TableCell align="right">{currencyFormatter.format(invoice.amount)}</TableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default InvoicesTable;
