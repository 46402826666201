import { combineReducers } from 'redux';

import { reducer as snackbar } from './snackBarSlice';
import { reducer as auth } from './authSlice';
import { reducer as invoices } from './invoicesSlice';

export default combineReducers({
    snackbar,
    auth,
    invoices,
});
