import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { actions, selectNotifications } from 'store/reducers/snackBarSlice';

import { NotificationKey } from 'models/snackBar';

let displayed: NotificationKey[] = [];

const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id: NotificationKey) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: NotificationKey) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key as NotificationKey)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(actions.removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key as NotificationKey);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;
