import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useQuery } from 'hooks';
import { actions } from 'store/reducers/authSlice';
import logo from 'img/logo.png';
import { TokenType } from 'enums/auth';
import path from '../../path';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const classes = useStyles();
    const query = useQuery();
    const history = useHistory();
    const dispatch = useDispatch();
    const [document, setDocument] = useState<string>('');
    const onDocumentChange = (event: ChangeEvent<HTMLInputElement>) => setDocument(event.target.value);

    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault();
        try {
            dispatch(actions.signIn({ next: query.get('next'), document }));
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const token = sessionStorage.getItem(TokenType.AccessToken);
        if (token !== null) {
            dispatch(actions.setIsAuthenticated(true));
            const next = query.get('next');
            const nextPath = next || path.HOME;
            history.push(nextPath);
        }
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <img src={logo} alt="LAW & Medicine" />
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    LAW & Medicine Auto Gestión
                </Typography>
                <form className={classes.form} noValidate onSubmit={onSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="document"
                        label="RUC / Cédula"
                        value={document}
                        onChange={onDocumentChange}
                        helperText="Ingrese su RUC. Si no cuenta con el mismo puede ingresar con su cédula"
                        autoFocus
                    />
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        Ingresar
                    </Button>
                </form>
            </div>
        </Container>
    );
}
