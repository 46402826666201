import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/index';
import { Notification, NotificationKey, SnackBarState } from 'models/snackBar';
import { NotificationLevel } from 'enums/snackBar';

const initialState: SnackBarState = {
    notifications: [],
};

export const payOrderSlice = createSlice({
    name: 'snackbar',
    reducers: {
        enqueueError: (state, action: PayloadAction<string>) => {
            const key = new Date().getTime() + Math.random();
            const notification = {
                message: action.payload,
                options: { variant: NotificationLevel.Error },
                key,
            };
            state.notifications = [...state.notifications, notification];
        },
        enqueueSnackbar: (state, action: PayloadAction<Notification>) => {
            let key = action.payload.options && action.payload.options.key;
            key = key || new Date().getTime() + Math.random();

            state.notifications = [...state.notifications, { ...action.payload, key }];
        },
        closeSnackbar: (state, action) => {
            const dismissAll = !action.payload;

            state.notifications = state.notifications.map((notification) =>
                dismissAll || notification.key === action.payload
                    ? { ...notification, dismissed: true }
                    : { ...notification },
            );
        },
        removeSnackbar: (state, action: PayloadAction<NotificationKey>) => {
            state.notifications = state.notifications.filter((notification) => notification.key !== action.payload);
        },
    },
    initialState,
});

export const { actions, reducer } = payOrderSlice;

export const selectNotifications = (state: RootState) => state.snackbar.notifications;
