import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import SignIn from 'pages/SignIn';
import PrivateApp from 'PrivateApp';
import PrivateRoute from 'components/PrivateRoute';
import path from './path';
import history from './history';

const App = () => (
    <Router history={history}>
        <Switch>
            <Route path={path.LOGIN} component={SignIn} />
            <PrivateRoute path={path.HOME} component={PrivateApp} />
        </Switch>
    </Router>
);

export default App;
