import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './styles';

const Loading = () => {
    const classes = useStyles();
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.root}>
            <CircularProgress color="primary" />
        </Grid>
    );
};

export default Loading;
