import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthState, RequestSignIn, User } from 'models/auth';
import { RootState } from 'store/index';

const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
};

const authSlice = createSlice({
    name: 'auth',
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        doSignOut: (state) => {
            state.user = null;
            state.isAuthenticated = false;
        },
        fetchMe: () => {},
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        signIn: (state, action: PayloadAction<RequestSignIn>) => {},
        signOut: () => {},
    },
    initialState,
});

export const { actions, reducer } = authSlice;

export const selectAuth = (state: RootState) => state.auth;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectUser = (state: RootState) => state.auth.user;
