import { Invoice } from 'models/invoice';
import { SignInSuccessResponse } from 'models/auth';
import { TokenType } from 'enums/auth';
import client from './client';

export const downloadInvoice = async (invoiceId: string) => {
    const url = `/invoices/${invoiceId}/download/`;
    const response = await client.get(url, {
        responseType: 'blob',
    });
    return response.data;
};

export const getInvoice = async (invoiceId: string) => {
    const url = `/invoices/${invoiceId}/`;
    const response = await client.get(url);
    return response.data;
};

export const getInvoices = async (): Promise<Invoice[]> => {
    const response = await client.get('/invoices/');
    return response.data;
};

export const login = async (document: string): Promise<SignInSuccessResponse> => {
    const data = { document };
    const response = await client.post('/token/', data);
    return response.data;
};

export const logout = async (): Promise<SignInSuccessResponse> => {
    const refresh = sessionStorage.getItem(TokenType.RefreshToken);
    const data = { refresh };
    const response = await client.post('/logout/', data);
    return response.data;
};

export const me = async () => {
    const response = await client.get('/me/');
    return response.data;
};
