import React from 'react';
import Typography from '@material-ui/core/Typography';

import errorImg from 'img/error.png';
import useStyles from './styles';

const Error = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img alt="error" src={errorImg} />
            <Typography variant="h5">Ups! Ha ocurrido un error al recuperar los datos.</Typography>
        </div>
    );
};

export default Error;
