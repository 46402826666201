import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { TokenType } from 'enums/auth';

export const BASE_URL = process.env.REACT_APP_API_URL;

const client = axios.create({ baseURL: BASE_URL });

client.interceptors.response.use(
    (response) => {
        const newResponse = { ...response };
        if (newResponse.data && newResponse.headers['content-type'] === 'application/json') {
            newResponse.data = camelizeKeys(newResponse.data);
        }
        return newResponse;
    },
    async (error) => {
        const newError = { ...error };

        const originalRequest = error.config;
        const refresh = sessionStorage.getItem(TokenType.RefreshToken);
        if (refresh && error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshTokenResponse = await client.post('/token/refresh/', { refresh });
            if (refreshTokenResponse.status === 200) {
                sessionStorage.setItem(TokenType.AccessToken, refreshTokenResponse.data.access);
                return client(originalRequest);
            }
        }

        if (
            newError.response &&
            newError.response.data &&
            newError.response.headers['content-type'] === 'application/json'
        ) {
            newError.response.data = camelizeKeys(newError.response.data);
        }
        throw newError;
    },
);

client.interceptors.request.use((config) => {
    const newConfig = { ...config };

    const accessToken = sessionStorage.getItem(TokenType.AccessToken);
    if (accessToken) {
        newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (newConfig.headers['Content-Type'] === 'multipart/form-data') {
        return newConfig;
    }

    if (config.params) {
        newConfig.params = decamelizeKeys(config.params);
    }

    if (config.data) {
        newConfig.data = decamelizeKeys(config.data);
    }

    return newConfig;
});

export default client;
