import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { actions, selectUser } from 'store/reducers/authSlice';
import logo from 'img/logo.png';

const useStyles = makeStyles({
    logo: {
        maxWidth: 250,
    },
});

const Header = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const classes = useStyles();
    const onSignOut = () => dispatch(actions.signOut());
    useEffect(() => {
        dispatch(actions.fetchMe());
    }, []);
    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
                <img src={logo} alt="LAW & Medicine" className={classes.logo} />
            </Grid>
            <Grid item>
                <Grid container direction="column" justifyContent="center" alignItems="flex-end">
                    <Grid item>
                        <Typography variant="h4">Auto Gestión</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                            <Typography variant="h6" component="span">
                                {user && user.name}
                            </Typography>
                            <IconButton aria-label="delete" color="primary" onClick={onSignOut}>
                                <ExitToAppIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header;
