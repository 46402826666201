import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import { Invoice, InvoicesState } from 'models/invoice';

const initialState: InvoicesState = {
    loading: false,
    invoices: [],
    invoice: null,
    error: false,
};

const invoicesSlice = createSlice({
    name: 'invoices',
    reducers: {
        setInvoices: (state, action: PayloadAction<Invoice[]>) => {
            state.invoices = action.payload;
        },
        setInvoice: (state, action: PayloadAction<Invoice>) => {
            state.invoice = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        clean: () => initialState,
        fetchInvoices: () => {},
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchInvoice: (state, action: PayloadAction<string>) => {},
    },
    initialState,
});

export const { actions, reducer } = invoicesSlice;

export const selectInvoices = (state: RootState) => state.invoices.invoices;
export const selectInvoice = (state: RootState) => state.invoices.invoice;
export const selectLoading = (state: RootState) => state.invoices.loading;
export const selectError = (state: RootState) => state.invoices.error;
