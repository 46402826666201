import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            head: {
                fontWeight: 'bold',
            },
        },
    },
});

export default theme;
