import dayjs from 'dayjs';

export const dateFormatter = (date: string | null | undefined): string => {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY');
};

export const currencyFormatter = new Intl.NumberFormat('es-PY', {
    style: 'currency',
    currency: 'PYG',
});

export const reverse = (path: string, params: { [key: string]: string }) => {
    let newPath = path;
    Object.entries(params).forEach(([key, value]) => {
        newPath = newPath.replace(`:${key}`, value);
    });
    return newPath;
};
