import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { actions, selectInvoices } from 'store/reducers/invoicesSlice';
import InvoicesTable from './InvoicesTable';

const Invoices = () => {
    const dispatch = useDispatch();
    const invoices = useSelector(selectInvoices);
    useEffect(() => {
        dispatch(actions.fetchInvoices());
        return () => {
            dispatch(actions.clean());
        };
    }, []);
    return (
        <Grid container spacing={1}>
            <Grid item lg={12}>
                <Typography variant="h5">Facturas</Typography>
            </Grid>
            <Grid item lg={12}>
                <InvoicesTable invoices={invoices} />
            </Grid>
        </Grid>
    );
};

export default Invoices;
