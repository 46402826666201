import { all, call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { login, logout, me } from 'api';
import { TokenType } from 'enums/auth';
import { RequestSignIn, SignInSuccessResponse, User } from 'models/auth';
import { NotificationLevel } from 'enums/snackBar';
import path from '../../path';
import history from '../../history';
import { actions } from '../reducers/authSlice';
import { actions as snackBarActions } from '../reducers/snackBarSlice';

export function* signIn(action: PayloadAction<RequestSignIn>) {
    const { document, next } = action.payload;
    try {
        const tokens: SignInSuccessResponse = yield call(login, document);
        yield call([sessionStorage, sessionStorage.setItem], TokenType.AccessToken, tokens.access);
        yield call([sessionStorage, sessionStorage.setItem], TokenType.RefreshToken, tokens.refresh);
        yield put(actions.setIsAuthenticated(true));
        const nextPath = next !== path.LOGIN && next !== null ? next : path.HOME;
        yield call([history, history.push], nextPath);
    } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.data?.detail) {
            yield put(
                snackBarActions.enqueueSnackbar({
                    message: e.response.data.detail,
                    options: { variant: NotificationLevel.Error },
                }),
            );
        }
        yield call([sessionStorage, sessionStorage.clear]);
    }
}

export function* fetchMe() {
    try {
        const user: User = yield call(me);
        yield put(actions.setUser(user));
    } catch (e) {
        console.log(e);
    }
}

export function* signOut() {
    try {
        yield call(logout);
    } catch (e) {
        console.log(e);
    }
    yield call([sessionStorage, sessionStorage.clear]);
    yield put(actions.doSignOut());
    yield call([history, history.push], path.LOGIN);
}

export default function* watchAuth() {
    yield all([
        takeLatest(actions.signIn.type, signIn),
        takeLatest(actions.signOut.type, signOut),
        takeLatest(actions.fetchMe.type, fetchMe),
    ]);
}
