import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';

import Invoice from 'pages/Invoice';
import Invoices from 'pages/Invoices';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Container from '@material-ui/core/Container';
import appPath from './path';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    container: {
        minHeight: '100%',
    },
    paper: {
        minHeight: '100%',
        padding: theme.spacing(2),
    },
    header: {
        height: 80,
    },
    content: {
        minHeight: '80vh',
        paddingTop: theme.spacing(2),
    },
}));

const PrivateApp = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="md" className={classes.root}>
            <Paper className={classes.paper}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="stretch"
                    className={classes.container}
                >
                    <Grid item className={classes.header}>
                        <Header />
                    </Grid>
                    <Grid item className={classes.content}>
                        <Switch>
                            <Route path={appPath.INVOICE} component={Invoice} />
                            <Route path={appPath.HOME} component={Invoices} />
                        </Switch>
                    </Grid>
                    <Grid item>
                        <Footer />
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default PrivateApp;
