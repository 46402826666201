import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { getInvoice, getInvoices } from 'api';
import { Invoice } from 'models/invoice';
import { actions as snackBarActions } from 'store/reducers/snackBarSlice';
import { actions } from '../reducers/invoicesSlice';

export function* fetchInvoices() {
    yield put(actions.setLoading(true));
    try {
        const invoices: Invoice[] = yield call(getInvoices);
        yield put(actions.setInvoices(invoices));
    } catch (e) {
        yield put(snackBarActions.enqueueError('Hubo un error al recuperar las facturas.'));
    } finally {
        yield put(actions.setLoading(false));
    }
}

export function* fetchInvoice(action: PayloadAction<string>) {
    yield put(actions.setLoading(true));
    try {
        const invoice: Invoice = yield call(getInvoice, action.payload);
        yield put(actions.setInvoice(invoice));
    } catch (e) {
        yield put(actions.setError(true));
        yield put(snackBarActions.enqueueError('Hubo un error al recuperar la factura.'));
    } finally {
        yield put(actions.setLoading(false));
    }
}

export default function* watchInvoices() {
    yield all([
        takeLatest(actions.fetchInvoices.type, fetchInvoices),
        takeLatest(actions.fetchInvoice.type, fetchInvoice),
    ]);
}
